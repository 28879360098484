<template>
    <div id="main">
        <bread-crumb
                :type="3"
                :status="examInfo.exam_type==7?4:2"
                style="margin-top:20px;margin-left:-50px"
        ></bread-crumb>
        <div class="section">
            <img src="@/assets/fonts/fail.png" style="width: 100px; height: 100px;"/>
            <div class="pay-status" v-if="examInfo.exam_type==7">
                报名失败
            </div>
            <div class="pay-status" v-else>
                支付失败
            </div>
            <div class="testBox">
                <div>
                    {{ studentInfo.student_name }}同学，你报考的“{{
                    examInfo.exam_name
                    }}”已锁定考试名额，考试信息如下：
                </div>
                <div v-if="examInfo.exam_type == 7">预订考点省份：{{ examInfo.province }}</div>
                <div v-else>预订考点：{{ examInfo.city }}</div>
                <div>考试时间：{{ examInfo.execute_time }}</div>
                <div style="color: #999999; font-size: 14px;margin-top: 30px;">
                    报名支付失败可在“考试列表-查看报名信息”中查看，请在<span
                        style="color:#EE7602;font-size:20px;font-weight:400;"
                >15分钟</span
                >内完成支付。
                </div>
            </div>
            <div class="btnBox">
                <el-button
                        style="background: #fff; color: #563279; border: 1px solid #563279"
                >查看报名信息
                </el-button
                >
                <el-button
                        style="background: #563279; color: #fff;border:1px solid #563279"
                >返回考试列表
                </el-button
                >
                <el-button
                        style="background: #563279; color: #fff;border:1px solid #563279"
                >重新支付
                </el-button
                >
            </div>
        </div>
    </div>
</template>

<script>
    import breadCrumb from "c/breadcrumb";
    import {getOrderInfo} from "r/index/order.js";

    export default {
        data() {
            return {
                examInfo: {},
                studentInfo: {},
            };
        },
        created() {
            if (!window.localStorage.getItem('exam_uuid') || window.localStorage.getItem('exam_uuid') == '' || window.localStorage.getItem('exam_uuid') == 'null') {
                return this.$router.replace({name: 'examlist'})
            }
            this.getOrderInfo();
        },
        methods: {
            // 获取订单信息
            async getOrderInfo() {
                let {data: res} = await getOrderInfo({
                    exam_uuid: window.localStorage.getItem("exam_uuid"),
                });
                console.log(res);
                if (res.code !== 200) return this.$message.error(res.message);
                this.examInfo = res.info;
                this.studentInfo = res.student;
            },
        },
        components: {
            breadCrumb,
        },
    };
</script>

<style scoped lang="scss">
    #main {
        width: 100%;
        overflow: auto;
        min-height: 80%;

        .el-main {
            max-width: 1300px;
            margin: 6px auto;
            padding: 20px 50px;
        }

        .section {
            background: #ffffff;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 auto;
            padding: 60px 0 80px;

            .pay-status {
                height: 16px;
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #000000;
                line-height: 16px;
                margin: 17px 0 30px;
            }

            .testBox {
                width: 620px;
                height: 108px;
                margin-bottom: 130px;
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 36px;

                div:last-child {
                    line-height: 20px;
                }
            }

            .btnBox {
                .el-button {
                    width: 140px;
                    margin-right: 40px;
                    border: 1px solid #563279;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
</style>
