<template>
  <div>
    <el-breadcrumb
      separator="/"
      v-if="type === 1"
    >
      <el-breadcrumb-item :to="{ path: '/' }">考试列表</el-breadcrumb-item>
      <el-breadcrumb-item>
        <span style="color: #56327a" v-if="step === 1">查看详情</span>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-breadcrumb
      separator="/"
      v-if="type === 2"
    >
      <el-breadcrumb-item :to="{ path: '/' }">考试列表</el-breadcrumb-item>
      <el-breadcrumb-item>
        <span style="color: #56327a" v-if="step === 1">身份认证</span>
        <span style="color: #56327a" v-if="step === 2">确认个人信息</span>
        <span style="color: #56327a" v-if="step === 3">填写邀请码</span>
        <span style="color: #56327a" v-if="step === 4">选择考点城市</span>
        <span style="color: #56327a" v-if="step === 5">选择考点省份</span>
        <span style="color: #56327a" v-if="step === 6">确认报名信息</span>
        <span style="color: #56327a" v-if="step === 7">查看报名信息</span>
        <span style="color: #56327a" v-if="step === 8">查看预定信息</span>
        <span style="color: #56327a" v-if="step === 9">我的考试</span>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-breadcrumb
            separator="/"
            v-if="type === 3"
    >
      <el-breadcrumb-item :to="{ path: '/' }">考试列表</el-breadcrumb-item>
      <el-breadcrumb-item>
        <span v-if="status === 1" style="color: #56327a">支付成功</span>
        <span v-if="status === 2" style="color: #56327a">支付失败</span>
        <span v-if="status === 3" style="color: #56327a">报名成功</span>
        <span v-if="status === 4" style="color: #56327a">报名失败</span>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-breadcrumb
      separator="/"
      v-if="type === 4"
    >
      <el-breadcrumb-item :to="{ path: '/' }">考试列表</el-breadcrumb-item>
      <el-breadcrumb-item>
        <span style="color: #56327a">取消报名成功</span>
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
    props:{
        type:Number,
        step:Number,
        status:Number  //1-支付成功，2-支付失败，3-报名成功，4-报名失败
    }
}
</script>

<style scoped lang="scss">
// 面包屑样式
@import "a/scss/beradcrumb";

</style>
