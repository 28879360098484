import { request } from "./network";

// 取消订单
export function cancelOrder(data) {
  return request({
    method: "post",
    url: "/web/order/cancelOrder",
    data,
  });
}

// 报名列表
export function getOrderList(data) {
  return request({
    method: "get",
    url: "/web/order/getOrderList",
    data,
  });
}

// 获得报名信息-订单信息
export function getOrderInfo(params) {
  return request({
    method: "get",
    url: "/web/order/getOrderInfo",
    params,
  });
}

// 取消预订
export function unSeat(data) {
  return request({
    method: "post",
    url: "/web/book/unSeat",
    data,
  });
}
// 获取预约订单支付配置
export function getWxConfig(params) {
  return request({
    method: "get",
    url: "/web/pay/getWxPreselectionConfig",
    params,
  });
}

// 申请延期 1-延期 0-取消延期
export function delayOrder(params) {
  return request({
    method: "post",
    url: "/web/order/delayOrder",
    params,
  });
}

//补填邀请码-订单报名成功以后
export function updateInviteCode(data) {
  return request({
    method: "post",
    url: "/web/order/setInviteCode",
    data,
  });
}
